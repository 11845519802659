<template>
  <a-modal
    width="60%"
    :visible="visible"
    title="添加道具"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handelOK"
  >
    <a-form
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 21 }"
      :model="formState"
      :rules="rules"
      ref="formRef"
    >
      <a-form-item label="道具：" name="prop_ids">
        <a-select
          v-model:value="formState.prop_ids"
          mode="multiple"
          placeholder="请选择道具"
          :showSearch="true"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in propList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.prop }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { getProp, addSceneProp, getSceneProp } from '@/api/index'
export default defineComponent({
  props: {
    visible: {},
    sceneId: {}
  },
  setup(props, ctx) {
    const formRef = ref()
    const formState = reactive({
      prop_ids: []
    })
    const propList = ref([])
    const rules = {
      prop_ids: [
        {
          required: true,
          trigger: 'blur',
          message: '请选择道具',
          whitespace: true,
          type: 'array'
        }
      ]
    }
    const handleCancel = () => {
      ctx.emit('update:visible', false)
    }
    const addScenePropHttp = () => {
      addSceneProp(
        Object.assign(formState, {
          scene_id: parseInt(props.sceneId)
        })
      ).then(res => {
        message.success('添加道具成功')
        handleCancel()
      })
    }
    const handelOK = () => {
      console.log(formState)
      formRef.value
        .validate()
        .then(() => {
          addScenePropHttp()
          // callback()
        })
        .catch(error => {
          console.log('error', error)
        })
    }
    // 获取列表数据
    const getProjectListHttp = () => {
      getProp({}).then(res => {
        propList.value = res.data
      })
    }
    // 获取已经有的道具
    const getBindScene = id => {
      getSceneProp({
        scene_id: parseInt(id)
      }).then(res => {
        formState.prop_ids = res.data.map(item => item.id)
        console.log(res)
      })
    }
    // 筛选道具输入项
    const filterOption = (inputValue, option) => {
      return option.children[0].children.indexOf(inputValue) >= 0
    }
    return {
      formRef,
      formState,
      rules,
      handleCancel,
      handelOK,
      getProjectListHttp,
      propList,
      getBindScene,
      filterOption
    }
  }
})
</script>
