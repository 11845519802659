import { ref, watch } from 'vue'

export function btnAndSearchHooks (callback?: () => void) {
  const btnIndex = ref(0)
  const changeIndex = (index: number) => {
    btnIndex.value = index
    if (callback && btnIndex.value === 0) {
      callback()
    }
  }
  return {
    changeIndex,
    btnIndex
  }
}
