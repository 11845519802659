
import { defineComponent, ref, reactive, toRefs, onMounted, computed } from 'vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import createScene from '@/views/senceManager/components/createScene.vue'
import { PROJECT_STATE, ROLE_STATE, MAJOR_STATE } from '@/hooks/config'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { getScene, deleteScene, sceneOverModif } from '@/api/index'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import sceneDialog from '@/views/senceManager/components/sceneDialog.vue'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '场景列表'
  },
  {
    id: 2,
    btnName: '新建场景'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '场景名称',
    dataIndex: 'scene',
    key: 'scene',
    width: 300
  },
  {
    title: '是否交付',
    key: 'scene',
    slots: { customRender: 'switch' }
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'SenceManager',
  components: {
    mangerPageHeader,
    createScene,
    sceneDialog
  },
  setup () {
    const sceneRef = ref()
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination },
      search: '',
      visibleProp: false,
      sceneId: null
    })
    // 获取道具
    const getPropHttp = (isDelete?: boolean) => {
      getScene({
        search: tableData.search,
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          getPropHttp()
        }
      })
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(getPropHttp)
    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      getPropHttp()
    }
    // 跳转编辑页面
    const goEidt = (item: any) => {
      const id = item.id
      router.push({ name: 'createScene', params: { id } })
    }
    // 跳转详情页面
    const goDetail = (id: number) => {
      router.push({ name: 'senceDes', params: { id } })
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      getPropHttp()
    }
    // 删除
    const deletePropHttp = (id: number) => {
      showConfirm({
        content: '此操作不可逆，确定删除该场景吗?',
        callBack: () => {
          deleteScene({
            id
          }).then(() => {
            message.success('删除成功')
            getPropHttp()
          })
        }
      })
    }
    const opendiaolg = (item: any) => {
      tableData.visibleProp = true
      tableData.sceneId = item.id
      sceneRef.value.getProjectListHttp()
      sceneRef.value.getBindScene(item.id)
    }
    const changeSwitch = (checked: boolean, id: number) => {
      sceneOverModif({
        id: id,
        isover: checked
      }).then(res => {
        getPropHttp()
      })
    }
    onMounted(() => {
      getPropHttp()
    })
    return {
      btnList,
      btnIndex,
      changeIndex,
      ...toRefs(tableData),
      goEidt,
      goDetail,
      changePage,
      fetchSearch,
      deletePropHttp,
      opendiaolg,
      sceneRef,
      changeSwitch
    }
  }
})
