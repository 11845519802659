
import { defineComponent, PropType } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
export interface BtnProps {
  id: number;
  btnName: string;
}
export default defineComponent({
  name: 'mangerPageHeader',
  props: {
    btnList: {
      type: Array as PropType<BtnProps[]>,
      required: true
    },
    value: {
      type: Number,
      defualt: 0
    },
    search: {
      type: Boolean,
      defualt: false
    }
  },
  components: {
    SearchOutlined
  },
  setup (props, context) {
    // 按钮切换
    const switchBtn = (index: number) => {
      context.emit('update:value', index)
      context.emit('changeIndex', index)
    }
    return {
      switchBtn
    }
  }
})
